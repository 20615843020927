<!-- 用电负荷 -->
<template>
  <div id="load">
    <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>用电负荷</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="回路:">
              <el-input v-model="formData.name"></el-input>
            </el-form-item>
            <el-form-item label="容量:">
              <el-input v-model="formData.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search">搜索</el-button>
              <el-button type="primary" icon="el-icon-download">导出</el-button>
            </el-form-item>
          </el-form>
          <el-button type="primary" icon="el-icon-plus">新建</el-button>
        </div>

        <el-table class="table" :data="tableData" :span-method="spanMethod">
          <el-table-column align="center" prop="name" label="场站名称">
          </el-table-column>
          <el-table-column align="center" prop="name" label="变压器">
          </el-table-column>
          <el-table-column align="center" prop="name" label="额定容量">
          </el-table-column>
          <el-table-column align="center" prop="name" label="回路名称">
          </el-table-column>
          <el-table-column align="center" prop="name" label="回路容量">
          </el-table-column>
          <el-table-column align="center" prop="name" label="实时负载">
          </el-table-column>
          <el-table-column align="center" prop="name" label="最大需量">
          </el-table-column>
          <el-table-column align="center" prop="name" label="可调配容量">
          </el-table-column>
          <el-table-column align="center" prop="name" label="开关名称">
          </el-table-column>
          <el-table-column label="操作" width="150" align="center">
            <template slot-scope="scope">
              <el-button  type="warning" size="mini">编辑</el-button>
              <el-button  type="danger" size="mini" @click="deleteDialog=true">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 删除 -->
    <el-dialog title="删除" :visible.sync="deleteDialog" width="20%">
      <div class="deleteCenter">确定删除该条信息?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialog = false">取 消</el-button>
        <el-button type="primary" @click="deleteDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { eventApi } from "@/api/event.js";
export default {
  name: "",

  data() {
    return {
      formData: {
        name: "",
      },
      tabsData: ["日报", "月报", "年报"],
      deleteDialog:false,
      tableData: [
        {
          name: "*主进线柜",
          zero: "6554576",
          one: "50",
          two: "42",
        },
        {
          name: "电容柜",
          zero: "20",
          one: "50",
          two: "42",
        },
      ],
      select: 0,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      checkedNodes: [],
      defaultProps: {
        children: "children",
        id: "id",
        label: "label",
      },
      treeData: [
        {
          id: 1,
          label: "配电室",
          children: [
            {
              id: 11,
              label: "高压侧",
            },
            {
              id: 12,
              label: "低压侧",
            },
            {
              id: 13,
              label: "变压器",
            },
            {
              id: 14,
              label: "直流屏",
            },
          ],
        },
        {
          id: 2,
          label: "强变",
        },
        {
          id: 3,
          label: "柱变",
        },
        {
          id: 4,
          label: "强电间",
          children: [
            {
              id: 15,
              label: "高压侧",
            },
            {
              id: 16,
              label: "低压侧",
            },
            {
              id: 17,
              label: "变压器",
            },
            {
              id: 18,
              label: "直流屏",
            },
          ],
        },
      ],
    };
  },

  mounted() {
    // this.queryOperation();
  },

  methods: {
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    changeSize(pageSize) {
      this.requestParam.pageNum = pageSize;
      this.queryOperation();
    },
    switchTabs(index) {
      this.select = index;
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryOperation();
    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
      // this.updateSelectAllState();
      console.log(this.checkedNodes);
    },
    queryOperation() {
      eventApi.operation(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
  },
};
</script>
<style scoped>
#load {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/routerImg.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}
/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}
.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}
::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}
::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}
::v-deep.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}
.main {
  width: 1280px;
  padding: 20px;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
}
.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}
/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 0 20px 0;
}
.query-form :deep() .el-form {
  height: 40px;
}
.form :deep() .el-icon-date {
  color: #aed6ff;
}
.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}
.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}
.form :deep().el-date-editor {
  width: 132px;
}
.hour :deep() .el-select {
  width: 56px;
  padding: 0;
}
.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
}
/* 表格 */

/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}
.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}
::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}
::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}
.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table__fixed-right::before {
  height: 0px;
}
::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 100px;
  height: 35px;
  background: #2142a1;
  border: 0;
  color: #fff;
}
</style>
